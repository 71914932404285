<template>
  <basic-container>
    <head-layout :head-btn-options="headBtnOptions"
                 :head-title="$t('cip.plat.sys.tenant.title.indexHeadTitle')"
                 v-on="onHeadEvent">
    </head-layout>
    <grid-head-layout ref="searchFrom"
                      :search-columns="searchColumns"
                      v-model="searchForm"
                      v-on="onSearchEvent">
    </grid-head-layout>
    <grid-layout :before-open="beforeOpen"
                 v-bind="bindVal"
                 v-on="onEvent"
                 ref="gridLayOut"
                 @page-current-change="getList"
                 @page-size-change="getList"
                 @page-refresh-change="getList"
                 @gird-handle-select-click="selectionChange">
      <template slot="accountNumber" slot-scope="{row}">
        <el-tag size="mini">{{ row.accountNumber > 0 ? row.accountNumber : '不限制' }}</el-tag>
      </template>
      <template slot="expireTime"
                slot-scope="{row}">
        <el-tag size="mini">{{ row.expireTime ? row.expireTime : '不限制' }}</el-tag>
      </template>
    </grid-layout>
    <el-dialog :title="$t('cip.plat.sys.tenant.field.authTitle')"
               :visible.sync="box"
               append-to-body
               width="450px">
      <avue-form :option="settingOption"
                 v-model="settingForm"
                 @submit="handleSubmit" />
    </el-dialog>
    <el-dialog :title="$t('cip.plat.sys.tenant.field.dataSourceTitle')"
               append-to-body
               :visible.sync="datasourceBox"
               width="450px">
      <avue-form :option="datasourceOption"
                 v-model="datasourceForm"
                 @submit="handleDatasourceSubmit" />
    </el-dialog>
    <el-dialog :title="$t('cip.plat.sys.tenant.field.packageSetTitle')"
               append-to-body
               :visible.sync="packageBox"
               width="450px">
      <avue-form ref="formPackage"
                 :option="packageOption"
                 v-model="packageForm"
                 @submit="handlePackageSubmit" />
    </el-dialog>
    <el-dialog :title="$t('cip.plat.sys.tenant.field.packageManageTitle')"
               append-to-body
               :visible.sync="packageSettingBox"
               width="1000px">
      <tenant-package></tenant-package>
    </el-dialog>
  </basic-container>
</template>

<script>
import {PAGE_CONSTANT} from "@/util/pageConstantEnum";
import { getList, getDetail, remove, update, add, setting, datasource, packageInfo, packageSetting } from "@/api/system/tenant";
import { getDetail as packageDetail } from "@/api/system/tenantpackage";
import { mapGetters } from "vuex";
import { getMenuTree } from "@/api/system/menu";
import { validatenull } from "@/util/validate";
import GridLayout from "@/views/components/layout/grid-layout";
import Form from "./onlineDev/onlineDev/abilityDesign/Form";

export default window.$crudCommon({
    components: { GridLayout },
    data() {
      return {
        box: false,
        datasourceBox: false,
        packageBox: false,
        packageSettingBox: false,
        settingForm: {},
        settingOption: {
          column: [
            {
              label: this.$t('cip.plat.sys.tenant.field.accountNumber'),
              prop: "accountNumber",
              type: "number",
              span: 24,
            },
            {
              label: this.$t('cip.plat.sys.tenant.field.expireTime'),
              prop: "expireTime",
              type: "date",
              format: "yyyy-MM-dd",
              valueFormat: "yyyy-MM-dd",
              span: 24,
            },
          ]
        },
        datasourceForm: {},
        datasourceOption: {
          column: [
            {
              label: this.$t('cip.plat.sys.tenant.field.datasource'),
              prop: "datasourceId",
              search: true,
              span: 24,
              type: "select",
              dicUrl: "/api/sinoma-develop/datasource/select",
              props: {
                label: "name",
                value: "id"
              },
              rules: [{
                required: true,
                message: this.$t("cip.cmn.rule.selectWarning") + this.$t('cip.plat.sys.tenant.field.datasource'),
                trigger: "blur"
              }]
            },
          ]
        },
        packageForm: {},
        packageOption: {
          column: [
            {
              label: this.$t('cip.plat.sys.tenant.field.packageName'),
              prop: "packageId",
              search: true,
              span: 24,
              type: "select",
              dicUrl: "/api/sinoma-system/tenant-package/select",
              props: {
                label: "packageName",
                value: "id"
              }
            },
            {
              label: this.$t('cip.plat.sys.tenant.field.menuView'),
              prop: "menuId",
              span: 24,
              type: "tree",
              dicData: [],
              hide: true,
              multiple: true,
              props: {
                label: "title"
              },
            },
          ]
        },
        headBtnOptions: [{
          label: this.$t('cip.cmn.btn.addBtn'),
          emit: "head-add",
          type: "button",
          icon: ""
        }, {
          label: this.$t('cip.cmn.btn.delBtn'),
          emit: "head-romve",
          type: "button",
          icon: ""
        }, {
          label: this.$t('cip.plat.sys.tenant.btn.authSetting'),
          emit: "handle-setting",
          type: "button",
          icon: ""
        }, {
          label: this.$t('cip.plat.sys.tenant.btn.dataSourceSetting'),
          emit: "handle-datasource",
          type: "button",
          icon: ""
        }, {
          label: this.$t('cip.plat.sys.tenant.btn.packageSetting'),
          emit: "handle-package",
          type: "button",
          icon: ""
        }, {
          label: this.$t('cip.plat.sys.tenant.btn.packageManage'),
          emit: "handle-package-setting",
          type: "button",
          icon: ""
        }]
      }
    },
    watch: {
      'packageForm.packageId'() {
        if (!validatenull(this.packageForm.packageId)) {
          packageDetail(this.packageForm.packageId).then(res => {
            this.packageForm.menuId = res.data.data.menuId;
            this.initData();
          });
        }
      }
    },
    computed: {
      permissionList() {
        return {
          addBtn: this.vaildData(this.permission.param_add, false),
          viewBtn: this.vaildData(this.permission.param_view, false),
          delBtn: this.vaildData(this.permission.param_delete, false),
          editBtn: this.vaildData(this.permission.param_edit, false)
        };
      },
      ids() {
        let ids = [];
        this.selectionList.forEach(ele => {
          ids.push(ele.id);
        });
        return ids.join(",");
      },
      tenantId() {
        return this.selectionList[0].tenantId;
      },
      onHeadEvent() {
        return {
          'head-add': this.clickHeadAdd,
          'head-romve': this.clickHeadRomve,
          'handle-setting': this.handleSetting,
          'handle-datasource': this.handleDatasource,
          'handle-package': this.handlePackage,
          'handle-package-setting': this.handlePackageSetting
        }
      },
      gridRowBtn() {
        let result = [];
        //新增按钮
          result.push({
            label: this.$t('cip.cmn.btn.viewBtn'),
            emit: "grid-view",
            type: "text",
            icon: ""
          });
          result.push({
            label: this.$t('cip.cmn.btn.editBtn'),
            emit: "grid-edit",
            type: "text",
            icon: ""
          });
          result.push({
            label: this.$t('cip.cmn.btn.delBtn'),
            emit: "grid-romve",
            type: "text",
            icon: ""
          });
        return result;
      },
      onEvent() {
        return {
          'grid-view': this.rowView,
          'grid-edit': this.clickRowEdit,
          'grid-romve': this.rowDel,
        }
      }
    },
    methods: {
      selectionChange(list) {
        this.selectionList = list;
        console.log(this.selectionList)
      },
      beforeOpen(done, type) {
        if (["view", "edit"].includes(type)) {
          getDetail(this.$refs.gridLayOut.currentRow.id).then(res => {
            const data = res.data.data;
            // if (!(data.accountNumber > 0)) {
            //   data.accountNumber = "不限制";
            // }
            // if (!data.expireTime) {
            //   data.expireTime = "不限制";
            // }
            this.$refs.gridLayOut.currentRow = data;
          });
        }
        done();
      },
      clickHeadAdd() {
        this.$router.push({
          path: '/system/tenant/add',
          query: {
            type: 'add',
          }
        });
      },
      //修改
      clickRowEdit(row) {
        this.$router.push({
          path: '/system/tenant/edit',
          query: {
            id: row.id,
            type: 'edit',
          }
        })
      },
      rowView(row) {
        this.$router.push({
          path: '/system/tenant/view',
          query: {
            id: row.id,
            type: 'view',
          }
        })
      },
      handleSetting() {
        if (this.selectionList.length === 0) {
          this.$message.warning(this.$t('cip.cmn.msg.warning.chooseAtLeastOne'));
          return;
        }
        if (this.selectionList.length === 1) {
          getDetail(this.selectionList[0].id).then(res => {
            const data = res.data.data;
            this.settingForm.accountNumber = data.accountNumber;
            this.settingForm.expireTime = data.expireTime;
          });
        } else {
          this.settingForm.accountNumber = -1;
          this.settingForm.expireTime = '';
        }
        this.box = true;
      },
      handleDatasource() {
        if (this.selectionList.length === 0) {
          this.$message.warning(this.$t('cip.cmn.msg.warning.chooseAtLeastOne'));
          return;
        }
        if (this.selectionList.length !== 1) {
          this.$message.warning(this.$t('cip.cmn.msg.warning.selectOneWarning'));
          return;
        }
        getDetail(this.selectionList[0].id).then(res => {
          const data = res.data.data;
          this.datasourceForm.datasourceId = data.datasourceId;
        });
        this.datasourceBox = true;
      },
      initData() {
        getMenuTree().then(res => {
          const column = this.findObject(this.packageOption.column, "menuId");
          column.dicData = res.data.data;
        });
      },
      handlePackage() {
        getMenuTree().then(res => {
          const column = this.findObject(this.packageOption.column, "menuId");
          column.dicData = res.data.data;
        });
        if (this.selectionList.length === 0) {
          this.$message.warning(this.$t('cip.cmn.msg.warning.chooseAtLeastOne'));
          return;
        }
        if (this.selectionList.length !== 1) {
          this.$message.warning(this.$t('cip.cmn.msg.warning.selectOneWarning'));
          return;
        }
        if (this.selectionList.length === 1) {
          packageInfo(this.selectionList[0].id).then(res => {
            const data = res.data.data;
            this.packageForm.packageId = data.id;
            this.packageForm.menuId = data.menuId;
          });
        } else {
          this.packageForm.menuId = '';
        }
        this.packageBox = true;
        //更新字典远程数据
        setTimeout(() => {
          const form = this.$refs.formPackage;
          form.updateDic('packageId');
        }, 10);
      },
      handlePackageSetting() {
        this.packageSettingBox = true;
      },
      handleSubmit(form, done, loading) {
        setting(this.ids, form).then(() => {
          this.$message({
            type: "success",
            message: this.$t('cip.cmn.msg.success.setSuccess')
          });
          done();
          this.box = false;
        }, error => {
          window.console.log(error);
          loading();
        })
      },
      handleDatasourceSubmit(form, done, loading) {
        datasource(this.tenantId, form.datasourceId).then(() => {
          this.$message({
            type: "success",
            message: this.$t('cip.cmn.msg.success.setSuccess')
          });
          done();
          this.datasourceBox = false;
        }, error => {
          window.console.log(error);
          loading();
        });
      },
      handlePackageSubmit(form, done, loading) {
        packageSetting(this.tenantId, form.packageId).then(() => {
          this.$message({
            type: "success",
            message: this.$t('cip.cmn.msg.success.setSuccess')
          });
          done();
          this.packageBox = false;
        }, error => {
          window.console.log(error);
          loading();
        });
      },
    }
  },
  {
    //模块路径
    name: 'system/tenant',
  })
</script>

<style>
</style>
